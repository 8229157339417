<script>
    import { _ } from 'svelte-i18n'
    import { onMount } from 'svelte'
    import { errorMessages } from '$lib/utilities/errorMessages.js'
    import { get } from 'lodash-es'
    import BarringInstructionsModal from '$lib/Components/Error/BarringInstructionsModal.svelte'

    let barringInstructionsModal

    onMount(async () => {
        const { Modal } = await import('bootstrap')
        barringInstructionsModal = new Modal(document.getElementById('barringInstructionsModal'))
    })
</script>

<BarringInstructionsModal />

<p>
    {$_(get(errorMessages, 'MNO_SERVICE_CLASS_BARRED', 'error.message.unknown_error'))}
</p>

<div class="d-grid gap-2 mb-3">
    <button class="btn btn-info btn-lg text-white" on:click={() => barringInstructionsModal.show?.()}
        >{$_('payment.ippayment.barring.title.button')}</button
    >
</div>
