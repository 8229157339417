<script>
    import { page } from '$app/stores'
    import { _ } from 'svelte-i18n'

    export let href = ''
</script>

<div class="d-grid gap-2">
    <a href={href || $page.url.href} data-sveltekit-reload rel="reload" class="btn btn-primary btn-lg btn-block mb-3"
        >{$_('error.button.try_again')}</a
    >
</div>
