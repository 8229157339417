<script>
    import { _, number } from 'svelte-i18n'
    import { onMount } from 'svelte'
    import { payment } from '$lib/stores'
    import SiruAxios from '$lib/SiruAxios'
    import { captureException } from '$lib/utilities/errorTrackingTools.js'

    let showBalanceDisplay = false
    let loading = false
    let balance = false
    let nextUpdate = false

    const createNextUpdate = () =>
        nextUpdate.days === 0 && nextUpdate.hours === 0
            ? $_({ id: 'payment.allowance.my_balance.time_diff.minutes', values: { count: nextUpdate.minutes } })
            : `${$_({ id: 'payment.allowance.my_balance.time_diff.days', values: { count: nextUpdate.days } })}
                ${$_({ id: 'payment.allowance.my_balance.time_diff.hours', values: { count: nextUpdate.hours } })}`

    onMount(async () => {
        try {
            loading = true
            const { data } = await SiruAxios.get(`pay/rest/${$payment.uuid}/report`)
            balance = data.allowance
            nextUpdate = data.balanceChangesIn
        } catch (error) {
            captureException(error)
            console.error(error)
        } finally {
            loading = false
        }
    })
</script>

{#if !showBalanceDisplay}
    <p>
        {@html $_({
            id: 'error.message.purchaseRefusal_REASON_NOT_ENOUGH_BALANCE',
            values: {
                customerservice: chunks => `<a href="https://www.sirumobile.com/faq" target='_blank'>${chunks}</a>`,
                mysiru: chunks => `<a href="https://my.sirumobile.com" target='_blank'>${chunks}</a>`,
            },
        })}
    </p>
    <div class="d-grid gap-2 mb-3">
        <button class="btn btn-info btn-lg text-white" on:click={() => (showBalanceDisplay = true)}
            >{$_('payment.allowance.my_balance.message.link')}</button
        >
    </div>
{:else}
    <p class="text-secondary">
        {@html $_({
            id: 'payment.allowance.my_balance.message.info',
            values: {
                amount: $number($payment.actualPrice, { style: 'currency', currency: $payment.currency }),
                strong: chunks => `<strong>${chunks}</strong>`,
            },
        })}
    </p>

    <div class="mb-4">
        {#if loading}
            <div class="spinner-border text-danger" role="status" />
        {:else}
            <div id="user-current-balance" class="mb-2 display-1 fw-bold pb-4">
                {$number(balance, { style: 'currency', currency: $payment.currency })}
            </div>
            {#if nextUpdate}
                {$_('payment.allowance.my_balance.message.released')}
                <br />
                <div class="text-secondary">
                    {$_({ id: 'payment.allowance.my_balance.time_diff.in', values: { diff: createNextUpdate() } })}
                </div>
            {/if}
        {/if}
    </div>
{/if}
