<script>
    import { _ } from 'svelte-i18n'
</script>

<div
    class="modal fade"
    id="resolveFailedModal"
    tabindex="-1"
    aria-labelledby="resolveFailedModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{$_('payment.ippayment.help.datasaver.modalheading')}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body py-5">
                <p>
                    {$_('payment.ippayment.help.datasaver.info')}
                </p>
                <h2>Google Chrome</h2>
                <ol class="text-start">
                    <li>{$_('payment.ippayment.help.datasaver.instuctions1')}</li>
                    <li>
                        {@html $_({
                            id: 'payment.ippayment.help.datasaver.instuctions2',
                            values: { b: chunks => `<b>${chunks}</b>` },
                        })}
                    </li>
                    <li>
                        {@html $_({
                            id: 'payment.ippayment.help.datasaver.instuctions3',
                            values: { b: chunks => `<b>${chunks}</b>` },
                        })}
                    </li>
                    <li>
                        {@html $_({
                            id: 'payment.ippayment.help.datasaver.instuctions4',
                            values: { b: chunks => `<b>${chunks}</b>` },
                        })}
                    </li>
                    <li>{$_('payment.ippayment.help.datasaver.instuctions5')}</li>
                </ol>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal"
                    >{$_('word.close_element')}</button
                >
            </div>
        </div>
    </div>
</div>
