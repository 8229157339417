<script>
    import { _ } from 'svelte-i18n'
    import DNALogo from '$lib/images/payment/ip/icons/DNA_logo.png'
    import ElisaLogo from '$lib/images/payment/ip/icons/Elisa_logo.png'
    import TeliaLogo from '$lib/images/payment/ip/icons/Telia_logo.png'
</script>

<div
    class="modal fade"
    id="barringInstructionsModal"
    tabindex="-1"
    aria-labelledby="barringInstructionsLabel"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{$_('payment.ippayment.barring.title.how_to')}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body py-5">
                <div class="row text-start">
                    <div class="col-md-3 col-4">
                        <img src={DNALogo} alt="DNA" />
                    </div>
                    <div class="col-md-9 col-8">
                        <strong>{$_('payment.ippayment.barring.title.dna')}</strong>
                        <p>{$_('payment.ippayment.barring.message.dna')}</p>
                        <strong>
                            <a href="tel:044144044">044 144 044</a>
                        </strong>
                    </div>
                </div>

                <hr />

                <div class="row text-start">
                    <div class="col-md-3 col-4">
                        <img src={ElisaLogo} alt="elisa" />
                    </div>
                    <div class="col-md-9 col-8">
                        <strong>{$_('payment.ippayment.barring.title.elisa')}</strong>
                        <p>{$_('payment.ippayment.barring.message.elisa')}</p>
                        <strong>
                            <a href="https://verkkoasiointi.elisa.fi/" target="_blank" rel="noreferrer"> OmaElisa </a>
                        </strong>
                        <br />
                        <strong>
                            <a href="tel:010190262">010 190 262</a>
                        </strong>
                    </div>
                </div>

                <hr />

                <div class="row text-start">
                    <div class="col-md-3 col-4">
                        <img src={TeliaLogo} alt="telia" />
                    </div>
                    <div class="col-md-9 col-8">
                        <strong>{$_('payment.ippayment.barring.title.telia')}</strong>
                        <p>{$_('payment.ippayment.barring.message.telia')}</p>
                        <strong>
                            <a href="https://www.telia.fi/kirjaudu" target="_blank" rel="noreferrer">
                                {$_('payment.ippayment.barring.message.telia_manager')}
                            </a>
                        </strong>
                        <br />
                        <strong>
                            <a href="tel:020690400">020 690 400</a>
                        </strong>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal"
                    >{$_('word.close_element')}</button
                >
            </div>
        </div>
    </div>
</div>
