<script>
    import { _ } from 'svelte-i18n'
    import wait from '$lib/utilities/wait'
    import SiruAxios from '$lib/SiruAxios'
    import { payment } from '$lib/stores'

    let submitted = false
    let invalid = false
    let email = ''
    let phone = ''

    const handleSubmit = async event => {
        event.preventDefault()
        invalid = false

        if (email !== '' && phone !== '') {
            SiruAxios.post(`pay/rest/${$payment.uuid}/contact`, {
                customerEmail: email,
                customerNumber: phone,
            })
                .then(() => {
                    submitted = true
                })
                .catch(error => {
                    if (error.response?.status === 400) {
                        invalid = true
                    }
                })
        } else {
            invalid = true
        }
    }

    const handleClose = async () => {
        await wait(100) // Just to make sure the modal is closed before resetting the form
        submitted = false
        email = ''
        phone = ''
    }
</script>

<div class="modal fade" id="contactModal" tabindex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <form on:submit={handleSubmit}>
                <div class="modal-header">
                    <h5 class="modal-title">{$_('payment.contact_me.title.form_title')}</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        on:click={handleClose}
                    ></button>
                </div>
                <div class="modal-body py-5">
                    {#if !submitted}
                        <p class="mb-4 text-secondary">{$_('payment.contact_me.message.info')}</p>
                        <div class="mb-3">
                            <label for="email" class="form-label visually-hidden"
                                >{$_('payment.contact_me.email_address')}</label
                            >
                            <input
                                type="email"
                                class="form-control"
                                id="email"
                                required
                                class:is-invalid={invalid}
                                bind:value={email}
                                placeholder={$_('payment.contact_me.email_address')}
                            />
                        </div>
                        <div class="mb-3">
                            <label for="phone" class="form-label visually-hidden">{$_('phrase.phone_number')}</label>
                            <input
                                type="tel"
                                class="form-control"
                                id="phone"
                                required
                                class:is-invalid={invalid}
                                bind:value={phone}
                                placeholder={$_('phrase.phone_number')}
                            />
                        </div>
                        <input id="contact-submit" type="submit" value="send" class="d-none" />
                    {:else}
                        <p>{$_('payment.contact_me.message.thank_you')}</p>
                    {/if}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal" on:click={handleClose}
                        >{$_('word.close_element')}</button
                    >
                    {#if !submitted}<button type="submit" class="btn btn-primary"
                            >{$_('payment.contact_me.word.send')}</button
                        >
                    {/if}
                </div>
            </form>
        </div>
    </div>
</div>
