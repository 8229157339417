<script>
    import { page } from '$app/stores'
    import IconFix from '$lib/images/icons/icon_fix.png'
    import IconMan from '$lib/images/icons/icon_man.png'
    import IconPhone from '$lib/images/icons/icon_phone.png'
    import IconTimeout from '$lib/images/icons/icon_timeout.png'
    import IconX from '$lib/images/icons/icon_X.png'
    import IconBrowser from '$lib/images/icons/icon_browser.png'
    import TryAgain from '$lib/Components/Payment/Buttons/TryAgain.svelte'
    import Cancel from '$lib/Components/Payment/Buttons/Cancel.svelte'
    import IconMoney from '$lib/images/icons/icon_money.png'
    import ReturnToMerchant from '$lib/Components/Payment/Buttons/ReturnToMerchant.svelte'
    import { _ } from 'svelte-i18n'
    import { errorMessages, errorTitles } from '$lib/utilities/errorMessages.js'
    import { get } from 'lodash-es'
    import { payment } from '$lib/stores/index.js'
    import { PUBLIC_API_URL } from '$env/static/public'
    import { onMount } from 'svelte'
    import ContactMeModal from '$lib/Components/Error/ContactMeModal.svelte'
    import BalanceError from '$lib/Components/Error/BalanceError.svelte'
    import ContactMeModalButton from '$lib/Components/Error/ContactMeModalButton.svelte'
    import ResolveFailedError from '$lib/Components/Error/ResolveFailedError.svelte'
    import BarredError from '$lib/Components/Error/BarredError.svelte'
    import { isFinal } from '$lib/utilities/statusCodes'

    export let status
    export let code

    let icon = IconX
    let title = $page.error?.message || $_('error.title.general')

    if (status === 503) {
        icon = IconFix
        title = $_('error.title.maintenance')
    } else if (status === 404) {
        icon = IconMan
        title = $_('error.message.payment_not_found')
    } else {
        switch (code) {
            case 'PAYMENT_EXPIRED':
                icon = IconTimeout
                title = $_('error.message.payment_expired')
                break
            case 'PAYMENT_CANCELED':
                title = $_('error.message.payment_canceled')
                break
            case 'RM_MISSING_DEVICE_FINGERPRINT':
            case 'yoc_opt_out_fp_failed':
            case 'fp_failed':
                icon = IconBrowser
                title = $_('error.title.browser_issue')
                break
            case 'no_mobile_network':
                icon = IconPhone
                title = $_('error.title.mobile_network')
                break
            case 'RM_PERIODIC_LIMIT_EXCEEDED':
                icon = IconMoney
                title = $_('error.title.purchaseRefusal_REASON_NOT_ENOUGH_BALANCE')
                break
            default:
                icon = IconMan
                title = $_(get(errorTitles, code, 'error.title.general'))
                break
        }
    }

    let contactModal

    // Bs javascript relies on document being available. Dynamically import it onMount,
    // so we know for sure that we are in the browser and not in SSR
    onMount(async () => {
        const { Modal } = await import('bootstrap')
        contactModal = new Modal(document.getElementById('contactModal'))
    })
</script>

<article class="py-5">
    <ContactMeModal />

    <div class="mb-4">
        <img src={icon} aria-hidden="true" alt="Error icon" />
    </div>

    <h1>{title}</h1>

    {#if status === 503}
        <p>{$_('error.message.maintenance')}</p>
    {:else if status === 404}
        <!-- empty -->
    {:else if code === 'PAYMENT_EXPIRED'}
        <ReturnToMerchant />
    {:else if code === 'PAYMENT_CANCELED'}
        <ReturnToMerchant />
    {:else if code === 'fp_failed' || code === 'RM_MISSING_DEVICE_FINGERPRINT'}
        <p>{$_('error.message.browser_issue')}</p>
        <p>
            {$_('error.message.check_browser')}
        </p>

        <TryAgain />
        <Cancel />
    {:else if code === 'BLOCKED'}
        <p>
            {@html $_({
                id: errorMessages[code],
                values: { mysiru: chunks => `<a href="https://my.sirumobile.com" target='_blank'>${chunks}</a>` },
            })}
        </p>

        <ReturnToMerchant />

        <ContactMeModalButton onClick={() => contactModal.show?.()} />
    {:else if code === 'RM_BLOCKED'}
        <p>
            {@html $_({
                id: errorMessages[code],
                values: {
                    customerservice: chunks => `<a href="https://www.sirumobile.com/faq" target='_blank'>${chunks}</a>`,
                },
            })}
        </p>

        <ReturnToMerchant />

        <ContactMeModalButton onClick={() => contactModal.show?.()} />
    {:else if code === 'MNO_STRONG_AUTHENTICATION_REQUIRED'}
        <p>
            {@html $_({
                id: errorMessages[code],
                values: {
                    mobiilivarmenne: chunks => `<a href="https://mobiilivarmenne.fi" target='_blank'>${chunks}</a>`,
                },
            })}
        </p>

        <ContactMeModalButton onClick={() => contactModal.show?.()} />
    {:else if code === 'MNO_MSISDN_RESOLVE_FAILED'}
        <ResolveFailedError />

        <ReturnToMerchant />
        <ContactMeModalButton onClick={() => contactModal.show?.()} />
    {:else if code === 'no_mobile_network'}
        <p>{$_('error.message.mobile_network')}</p>

        <TryAgain href={`${PUBLIC_API_URL}/payment/ip/${$payment.uuid}/resolve`} />
        <Cancel />
    {:else if code === 'RM_PERIODIC_LIMIT_EXCEEDED'}
        <BalanceError />

        <ReturnToMerchant />
        <ContactMeModalButton onClick={() => contactModal.show?.()} />
    {:else if code === 'MNO_SERVICE_CLASS_BARRED'}
        <BarredError />

        <ReturnToMerchant />
        <ContactMeModalButton onClick={() => contactModal.show?.()} />
    {:else}
        <p>
            {$_(get(errorMessages, code, 'error.message.unknown_error'))}
        </p>

        {#if $payment.uuid}
            {#if !isFinal($payment.status)}
                <TryAgain />
            {:else}
                <ReturnToMerchant />
            {/if}

            <ContactMeModalButton onClick={() => contactModal.show?.()} />

            <hr />

            {#if !isFinal($payment.status)}
                <Cancel />
            {/if}
        {/if}
    {/if}
</article>

<style>
    img {
        height: 80px;
    }
</style>
