export const errorMessages = {
    BLOCKED: 'error.message.blocked',
    RM_BLOCKED: 'error.message.blacklisted_number',
    TOO_MANY_FAILED_PIN_ATTEMPTS: 'error.message.expired_pin_code',
    // Variant1
    CALL_TERMINATED: 'error.message.call_terminated',
    // Variant2
    MNO_MSISDN_RESOLVE_FAILED: 'error.message.MNO_MSISDN_RESOLVE_FAILED',
    MNO_SERVICE_CLASS_BARRED: 'error.message.operator_service_barred',
    MNO_INSUFFICIENT_CREDIT: 'error.message.operator_insufficient_credit',
    MNO_PERIODIC_LIMIT_EXCEEDED: 'error.message.operator_periodic_limit_exceeded',
    MNO_MAX_PRICE_EXCEEDED: 'error.message.operator_max_price_exceeded',
    MNO_SERVICE_DISABLED: 'error.message.operator_service_disabled',
    MNO_STRONG_AUTHENTICATION_REQUIRED: 'error.message.operator_strong_auth_required',
    // Variant4
    OPERATOR_SRV_DAILY_MAX_SPEND: 'error.v4.message.OPERATOR_SRV_DAILY_MAX_SPEND',
    OPERATOR_SRV_WEEKLY_MAX_SPEND: 'error.v4.message.OPERATOR_SRV_WEEKLY_MAX_SPEND',
    OPERATOR_SRV_MONTHLY_MAX_SPEND: 'error.v4.message.OPERATOR_SRV_MONTHLY_MAX_SPEND',
    OPERATOR_SRV_TYPE_DAILY_MAX_SPEND: 'error.v4.message.OPERATOR_SRV_TYPE_DAILY_MAX_SPEND',
    OPERATOR_SRV_TYPE_WEEKLY_MAX_SPEND: 'error.v4.message.OPERATOR_SRV_TYPE_WEEKLY_MAX_SPEND',
    OPERATOR_SRV_TYPE_MONTHLY_MAX_SPEND: 'error.v4.message.OPERATOR_SRV_TYPE_MONTHLY_MAX_SPEND',
    PERMANENTLY_BARRED: 'error.message.operator_service_barred',
    INSUFFICIENT_FUNDS: 'error.message.operator_insufficient_credit',
    UNKNOWN_MSISDN: 'error.message.ERROR_CUSTOMER_NUMBER_MISSING',
    UNROUTABLE: 'error.v4.message.UNROUTABLE',
    BARRED_MVNO: 'error.v4.message.OPERATOR_REJECTED',
    OPERATOR_REJECTED: 'error.v4.message.OPERATOR_REJECTED',
    TEMPORARY_BARRED: 'error.v4.message.TEMPORARY_BARRED',
    D2B_BARRED: 'error.v4.message.D2B_BARRED',
    MAX_SPEND_MSISDN: 'error.v4.message.MAX_SPEND_MSISDN',
    MONTHLY_MAX_SPEND_MSISDN: 'error.v4.message.MONTHLY_MAX_SPEND_MSISDN',
    DAILY_MAX_SPEND_MSISDN: 'error.v4.message.DAILY_MAX_SPEND_MSISDN',
}

export const errorTitles = {
    BLOCKED: 'error.title.purchaseRefusal_REASON_NUMBER_BLOCKED',
    RM_BLOCKED: 'error.title.purchaseRefusal_REASON_NUMBER_BLOCKED',
    // Variant1
    CALL_TERMINATED: 'error.title.call_terminated',
    // Variant2
    MNO_MSISDN_RESOLVE_FAILED: 'error.title.ERROR_CUSTOMER_NUMBER_MISSING',
    MNO_SERVICE_CLASS_BARRED: 'error.title.operator_service_barred',
    MNO_INSUFFICIENT_CREDIT: 'error.title.operator_insufficient_credit',
    MNO_PERIODIC_LIMIT_EXCEEDED: 'error.title.operator_periodic_limit_exceeded',
    MNO_MAX_PRICE_EXCEEDED: 'error.title.operator_max_price_exceeded',
    MNO_SERVICE_DISABLED: 'error.title.operator_service_disabled',
    MNO_STRONG_AUTHENTICATION_REQUIRED: 'error.title.operator_strong_auth_required',
    // Variant4
    PERMANENTLY_BARRED: 'error.title.operator_service_barred',
    D2B_BARRED: 'error.title.operator_service_barred',
    INSUFFICIENT_FUNDS: 'error.title.operator_insufficient_credit',
    OPERATOR_SRV_DAILY_MAX_SPEND: 'error.title.operator_periodic_limit_exceeded',
    OPERATOR_SRV_WEEKLY_MAX_SPEND: 'error.title.operator_periodic_limit_exceeded',
    OPERATOR_SRV_MONTHLY_MAX_SPEND: 'error.title.operator_periodic_limit_exceeded',
    OPERATOR_SRV_TYPE_DAILY_MAX_SPEND: 'error.title.operator_periodic_limit_exceeded',
    OPERATOR_SRV_TYPE_WEEKLY_MAX_SPEND: 'error.title.operator_periodic_limit_exceeded',
    OPERATOR_SRV_TYPE_MONTHLY_MAX_SPEND: 'error.title.operator_periodic_limit_exceeded',
    MAX_SPEND_MSISDN: 'error.title.operator_periodic_limit_exceeded',
    MONTHLY_MAX_SPEND_MSISDN: 'error.title.operator_periodic_limit_exceeded',
    DAILY_MAX_SPEND_MSISDN: 'error.title.operator_periodic_limit_exceeded',
}
