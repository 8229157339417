<script>
    import { _ } from 'svelte-i18n'
    import { onMount } from 'svelte'
    import { errorMessages } from '$lib/utilities/errorMessages.js'
    import { get } from 'lodash-es'
    import { browser } from '$app/environment'
    import ResolveFailedModal from '$lib/Components/Error/ResolveFailedModal.svelte'

    let datasaver = browser && 'connection' in navigator && navigator.connection.saveData === true

    let resolveFailedModal

    onMount(async () => {
        const { Modal } = await import('bootstrap')
        resolveFailedModal = new Modal(document.getElementById('resolveFailedModal'))
    })
</script>

<ResolveFailedModal />

<p class="mb-4">{$_(get(errorMessages, 'MNO_MSISDN_RESOLVE_FAILED', 'error.message.unknown_error'))}</p>
{#if datasaver}
    <p>
        <button class="btn btn-link text-decoration-none text-secondary" on:click={() => resolveFailedModal.show?.()}
            >{$_('payment.ippayment.help.datasaver.openlink')}</button
        >
    </p>
{:else}
    <p>{$_('payment.ippayment.help.datasaver.try_again_later')}</p>
{/if}
